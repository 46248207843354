import React, { useState, useRef } from "react"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"
import withLayout from "../components/layout"

import wise from "../imgs/wiselogonew.png"
import coinbaseGroup from "../imgs/coinbase-group.png"
import { toastSuccess, toastError } from "../utils/myToasts"
import { get } from "lodash"
import api from "../utils/api"
import InputRangeSlider from "../components/InputRangeSlider"
import root from "../utils/windowOrGlobal"

const Container = styled.div`
  max-width: 800px;
  padding: 0 20px 20px;
  margin: 0 auto;
  margin-top: 40px;

  text-align: center;
  p {
    margin: 0;
  }
  .heading {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  .sub-heading {
    text-align: left;
  }

  .submit-wrapper {
    text-align: center;
  }

  .normal-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  }

  .email-input-container {
    margin-top: 46px;
    .email-input {
      width: 280px;
      padding: 10px;
      margin-top: 10px;
      text-align: center;
      border-radius: 15px;
      border: 1px solid #000000;
    }
  }

  .input-subtitle {
    color: #6e6d7a;
  }

  .error-info {
    color: red;
    font-size: 14px;
    margin-top: 7px;
  }

  .min-max-range-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 56px;
  }

  .small-container {
    max-width: 424px;
    margin: 0 auto;
  }

  .payment-methods-wrapper {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 8px;
    .rectangle {
      display: flex;
      width: 192px;
      height: 136px;
      border: 1px solid #667af4;
      border-radius: 8px;
      background: #ffffff;
      padding: 18px 17px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .active {
      border: 3px solid #667af4;
    }
  }

  #subscription-resell-input {
    width: 126px;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    border-radius: 15px;
    border: 1px solid #000000;
  }

  .range-input {
    width: 100%;
  }

  .input-label-wrapper {
    margin-bottom: 36px;
    padding: 16px 0px;
  }

  .input-container {
    position: relative;
    margin-top: 30px;
    margin-bottom: 10px;
  }
`

const SubmitButton = styled.button`
  background: rgba(102, 122, 244, 0.19);
  border-radius: 15px;
  color: #667af4;
  border: 0;
  padding: 15px 10px 14px 12px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  &:disabled {
    background: rgba(102, 122, 244, 0.1);
    transform: scale(0.95);
    cursor: not-allowed;
  }
`

const ResellerRequest = () => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState("wise")
  const [subscriptionToResell, setSubscriptionToResell] = useState("")
  // const [highest4GPrice] = useState(105)
  const [highest5GPrice, setHighest5GPrice] = useState(125)
  const emailInpuRef = useRef(null)
  const [emailError, setEmailError] = useState("")
  const subscriptionResellInpuRef = useRef(null)
  const [subscriptionResellError, setSubscriptionResellError] = useState("")
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const validateEmail = e => {
    const email = e.target.value
    setEmail(email)
    if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address")
      ReactTooltip.show(emailInpuRef.current)
      return
    }
    setEmailError("")
    ReactTooltip.hide(emailInpuRef.current)
  }

  const validateSubscriptionResell = e => {
    const resellValue = e.target.value
    setSubscriptionToResell(resellValue)
    if (+resellValue < 1) {
      setSubscriptionResellError("The value must be at least 1")
      root.setTimeout(
        () => ReactTooltip.show(subscriptionResellInpuRef.current),
        100
      )
      return
    }
    setSubscriptionResellError("")
    ReactTooltip.hide(subscriptionResellInpuRef.current)
  }

  const isDisabled = () => {
    if (loading) {
      return true
    }
    if (!email || email.length === 0) {
      return true
    }
    if (emailError.length > 0 || subscriptionResellError.length > 0) {
      return true
    }
  }

  const handleSubmit = async () => {
    if (loading) {
      return
    }
    if (!subscriptionToResell) {
      setSubscriptionResellError("This field is required.")
      return
    }
    try {
      setLoading(true)
      const res = await api.post(`/create_reseller`, {
        email: email,
        // highest_4G_price: highest4GPrice,
        highest_5G_price: highest5GPrice,
        subscription_to_resell: subscriptionToResell,
        payment_method: paymentMethod,
      })
      if (res.status === 201 || (res.data && res.data.status === "success")) {
        toastSuccess(
          get(
            res,
            "data.message",
            "Your reseller request is submitted successfully"
          )
        )
        setLoading(false)
        setSubmitSuccess(true)
      }
    } catch (err) {
      toastError(
        get(err, "response.data.errors[0].detail", "Some error occured")
      )
      setLoading(false)
    }
  }

  return (
    <Container>
      <div class="small-container">
        <h3 className="heading">Reseller Request</h3>
      </div>
      {!submitSuccess && (
        <>
          <div class="sub-heading small-container">
            <p className="normal-text">
              We are accepting bids to become resellers of <br /> MountProxies’
              proxy servers!
            </p>
            <br />
            <p className="normal-text">
              We’ll choose resellers who make the highest <br /> bids, and offer
              the most reselling capabilities.
            </p>
          </div>
          <div className="email-input-container">
            <div className="small-container">
              <input
                type="email"
                className="email-input"
                placeholder="Email"
                name="email"
                value={email}
                onChange={validateEmail}
                ref={emailInpuRef}
                data-tip={emailError}
                data-event="focus"
                data-multiline={true}
                data-effect="solid"
              />
            </div>
            <div class="input-label-wrapper">
              <p class="input-title normal-text">
                Your contact email <sup>*</sup>
              </p>
            </div>
          </div>
          {/* <div className="min-max-input-wrapper 4g-price-wrapper">
            <div className="small-container">
              <InputRangeSlider
                min={90}
                max={139.99}
                currentValue={highest4GPrice}
                setCurrentValue={setHighest4GPrice}
                bubleSelector="bubble-4G"
              />
            </div>
            <div class="input-label-wrapper">
              <p class="input-title normal-text">
                What is the highest price you’d bid for 4G LTE with manual
                rotation service?
              </p>
              <p class="input-subtitle normal-text">
                Resellers with higher bids will be considered above lower bids.
              </p>
            </div>
          </div> */}
          <div className="min-max-input-wrapper 5g-price-wrapper">
            <div className="small-container">
              <InputRangeSlider
                min={90}
                max={159.99}
                currentValue={highest5GPrice}
                setCurrentValue={setHighest5GPrice}
                bubleSelector="bubble-5G"
              />
            </div>
            <div class="input-label-wrapper">
              <p class="input-title normal-text">
                What is the highest price you’d bid for 5G LTE with manual
                rotation service?
              </p>
              <p class="input-subtitle normal-text">
                Resellers with higher bids will be considered above lower bids.
              </p>
            </div>
          </div>
          <div>
            <input
              type="number"
              id="subscription-resell-input"
              name="number"
              min="1"
              value={subscriptionToResell}
              onChange={validateSubscriptionResell}
              ref={subscriptionResellInpuRef}
              data-tip={subscriptionResellError}
              data-event="focus"
              data-multiline={true}
              data-effect="solid"
            />
            {subscriptionResellError.length > 0 && (
              <p class="error-info">{subscriptionResellError}</p>
            )}
            <div class="input-label-wrapper">
              <p class="input-title normal-text">
                How many subscriptions will you resell? <sup>*</sup>
              </p>
            </div>
          </div>
          <div>
            <div className="payment-methods-wrapper">
              <div
                className={`rectangle ${
                  paymentMethod === "wise" ? "active" : ""
                }`}
                onClick={() => setPaymentMethod("wise")}
              >
                <img src={wise} alt={paymentMethod} style={{ width: "100%" }} />
              </div>
              <div
                className={`rectangle ${
                  paymentMethod === "coinbase" ? "active" : ""
                }`}
                onClick={() => setPaymentMethod("coinbase")}
              >
                <img src={coinbaseGroup} alt="coinbase" />
              </div>
            </div>
            <div class="input-label-wrapper">
              <p class="input-title normal-text">
                What payment method will you use?
              </p>
            </div>
          </div>
          <div>
            <SubmitButton disabled={isDisabled()} onClick={handleSubmit}>
              Submit Reseller Request
            </SubmitButton>
          </div>
        </>
      )}
      {submitSuccess && (
        <>
          <div class="sub-heading small-container submit-wrapper">
            <p className="normal-text">
              <SubmitButton disabled={true}>Status: Submitted</SubmitButton>
            </p>
            <br />
            <p className="normal-text">
              Thank you for submitting your reseller request! A member of our
              team will email you soon.
            </p>
          </div>
        </>
      )}
    </Container>
  )
}
export default withLayout(ResellerRequest)
