import React, { useEffect } from "react"
import styled from "styled-components"

const SliderContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
  .buble {
    position: absolute;
  }

  //   Slider style
  input[type="range"] {
    height: 34px;
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #000000;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #2497e3;
    height: 28px;
    width: 28px;
    border-radius: 27px;
    background: #d9d9d9;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -13.5px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #000000;
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #000000;
    border-radius: 1px;
    border: 0px solid #000000;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #2497e3;
    height: 28px;
    width: 28px;
    border-radius: 27px;
    background: #d9d9d9;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #000000;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: #000000;
    border: 0px solid #000000;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #2497e3;
    height: 28px;
    width: 28px;
    border-radius: 27px;
    background: #d9d9d9;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #000000;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #000000;
  }
`

const InputRangeSlider = ({
  min = 0,
  max = 100,
  currentValue,
  setCurrentValue,
  bubleSelector = "buble",
}) => {
  useEffect(() => {
    const ele = document.querySelector(`.${bubleSelector}`)
    if (ele) {
      const newVal = Number(((+currentValue - min) * 100) / (max - min))
      ele.style.left = `${newVal}%`
    }
  })
  return (
    <SliderContainer>
      <div className="min-max-range-wrapper">
        <span className="normal-text">${min}</span>
        <span className="normal-text">${max}</span>
      </div>
      <input
        className="range-input"
        type="range"
        min={min}
        max={max}
        defaultValue={currentValue}
        step="0.01"
        value={currentValue}
        onChange={e => {
          setCurrentValue(e.target.value)
        }}
      />
      <div className={`${bubleSelector} buble normal-text`}>
        ${currentValue}
      </div>
    </SliderContainer>
  )
}

export default InputRangeSlider
